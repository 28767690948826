<template>
  <v-list class="py-0">
    <template v-if="support">
      <!-- Popups -->
      <popup-support ref="support" />
      <popup-prompt ref="supportSubmit" />
    </template>

    <!-- Normal -->
    <template v-for="(item, i) in itemsFiltered">
      <div :key="i">
        <!-- Divider -->
        <template v-if="item.divider">
          <v-divider v-if="item.divider !== 'mobile' || $vuetify.breakpoint.smAndDown" />
          <v-divider v-if="$vuetify.breakpoint.smAndDown && !$vuetify.theme.dark" />
        </template>

        <!-- Item Link -->
        <template v-else-if="!item.children">
          <v-list-item :to="item.to" class="pl-4" @click="click(item)">
            <v-list-item-icon class="mr-6" :style="{ height: $vuetify.theme.options.navBarItemHeight }">
              <v-icon :color="getIconColor(item)">{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title :class="{ 'text-decoration-line-through': !item.to && !item.click }">
                {{ item.title }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>

        <!-- Group -->
        <template v-else>
          <v-list-group
            v-model="item.__model"
            class="borders"
            :class="{ inside: isItemGroupHighlighted(item) }"
            @click="click(item)"
          >
            <!-- Prepend Icon -->
            <template #prependIcon>
              <v-icon :color="getIconColorGroup(item)" :style="{ height: $vuetify.theme.options.navBarItemHeight }">{{
                item.icon
              }}</v-icon>
            </template>

            <!-- Append Icon -->
            <template #appendIcon>
              <v-icon class="mr-1">mdi-chevron-up</v-icon>
            </template>

            <template v-slot:activator>
              <v-list-item-content class="ml-n2">
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </template>

            <!-- Item Link -->
            <template v-for="(item2, j) in item.children">
              <v-list-item :key="j" :to="item2.to" @click="click(item2)">
                <v-list-item-icon class="mr-6">
                  <v-icon :color="getIconColor(item2)" :style="{ height: $vuetify.theme.options.navBarItemHeight }">{{
                    item2.icon
                  }}</v-icon>
                </v-list-item-icon>

                <v-list-item-title :class="{ 'text-decoration-line-through': !item2.to && !item2.click }">
                  {{ item2.title }}
                </v-list-item-title>
              </v-list-item>
            </template>
          </v-list-group>
        </template>
      </div>
    </template>
  </v-list>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  props: {
    items: {
      type: Array,
      required: true,
    },
    support: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(['getThemeMode']),
    itemsFiltered() {
      const type_id = this.$store.session.data.type_id;

      return this.items.filter((item) => {
        if (item.type_ids) {
          return item.type_ids.includes(type_id);
        }

        return !item.disabled;
      });
    },
    theme() {
      return this.$vuetify.theme.themes[this.$vuetify.theme.dark ? 'dark' : 'light'];
    },
  },
  methods: {
    isItemGroupHighlighted(item) {
      if (!item.group) return false;
      return this.$route.path.startsWith(`/${item.group}/`);
    },
    isItemHighlighted(item) {
      return this.$route.path === item.to;
    },
    isGroupOpen(item) {
      return item.__model === true;
    },
    getIconColor(item) {
      if (item.disabled) return this.theme.navBarItemIcon;

      const isHighlighted = this.isItemHighlighted(item);

      return isHighlighted
        ? item.colorHighlight || item.color || this.theme.navBarItemIconHighlight
        : item.color || this.theme.navBarItemIcon;
    },
    getIconColorGroup(item) {
      if (item.disabled) return this.theme.navBarItemIcon;

      const isHighlighted = this.isGroupOpen(item);

      return isHighlighted
        ? item.colorHighlight || item.color || this.theme.navBarItemIconHighlight
        : item.color || this.theme.navBarItemIcon;
    },
    click(item) {
      if (item.click) {
        this[item.click](item);
      }
    },
    async openSupport(item) {
      const fields = await this.$refs.support.open('Create Support Ticket');
      if (fields) {
        //this.$store.state.setLoading(true);
        //await this.$api.aircraft.insert(fields);
        //this.$store.state.setLoading(false);

        this.$refs.supportSubmit.open('Under Construction', 'This feature will arrive in a future update.');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.border2 {
  border: 1px solid red;
}
</style>
