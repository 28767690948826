<template>
  <v-container>
    <!-- Popups -->
    <popup-prompt ref="prompt" />
    <popup-account-edit ref="form" />
    <popup-account-password ref="reset" />

    <!-- Dialog Button Mobile-->
    <template v-if="$vuetify.breakpoint.smAndDown">
      <v-btn fab dark medium color="primary" fixed right bottom class="mr-4 mb-4" @click="create()">
        <v-icon dark>mdi-plus</v-icon>
      </v-btn>
    </template>

    <v-container class="mx-auto px-0" :class="{ 'py-0': $vuetify.breakpoint.smAndDown }" style="max-width: 850px">
      <v-card class="mb-6 mx-auto text-left" flat color="transparent">
        <!-- Alert -->
        <alert ref="alert" class="mb-6" transition="expand-transition" />

        <!-- Dialog Button Desktop -->
        <template v-if="!$vuetify.breakpoint.smAndDown">
          <!-- Hover -->
          <v-hover v-slot="{ hover }">
            <v-row no-gutters class="px-3 justify-center align-center">
              <v-col>
                <v-btn color="primary" elevation="0" width="102px" @click="create()">
                  <v-icon left>mdi-plus</v-icon>
                  New
                </v-btn>

                <download-csv
                  :data="entriesRaw"
                  :name="exportFileName"
                  @on-export="$store.alert.set({ type: 'success', message: 'Exported: ' + exportFileName, timeout: 2000 })"
                >
                  <v-btn class="ml-3" color="primary" elevation="0" outlined>Export</v-btn>
                </download-csv>
              </v-col>

              <v-col v-if="!$vuetify.breakpoint.smAndDown" class="text-right">
                <v-fade-transition>
                  <v-btn v-if="true" color="error" elevation="0" @click="reset()">RESET ALL</v-btn>
                </v-fade-transition>
              </v-col>
            </v-row>
          </v-hover>
          <!-- -->
        </template>
      </v-card>

      <!-- Body -->
      <v-data-table
        :headers="headers"
        :items="entries"
        :items-per-page="20"
        :search="search"
        :footer-props="{
          'items-per-page-options': [20, 100, -1],
        }"
        sort-by="name"
        class="elevation-1 px-2 pt-2"
      >
        <template v-slot:item.name="{ item }">
          <tr :class="{ 'text-decoration-line-through': !item.is_enabled }">
            <td>
              <div class="d-flex align-center">
                <v-avatar class="mr-2" size="26" dark>
                  <img :src="item.avatar" alt="" />
                </v-avatar>
                <p class="ma-0 pl-2 font-weight-medium">
                  {{ item.name }}
                </p>
              </div>
            </td>
          </tr>
        </template>

        <template v-slot:top>
          <v-toolbar flat>
            <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details></v-text-field>
          </v-toolbar>
        </template>

        <template v-slot:item.action="{ item }">
          <!-- Dropdown -->
          <v-menu right>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon :dark="$vuetify.theme.dark" v-bind="attrs" v-on="on">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>

            <v-list>
              <!-- Edit -->
              <v-list-item link @click.prevent="edit(item)">
                <v-icon class="pr-4">mdi-pencil-outline</v-icon>
                <v-list-item-title> Edit </v-list-item-title>
              </v-list-item>

              <!-- Enable/Disable -->
              <v-list-item link @click.prevent="toggleEnabled(item)">
                <v-icon class="pr-4">mdi-account-off-outline</v-icon>
                <v-list-item-title>{{ item.is_enabled ? 'Disable' : 'Enable' }}</v-list-item-title>
              </v-list-item>

              <!-- Remove -->
              <v-list-item link @click.prevent="remove(item)">
                <v-icon class="pr-4" color="red">mdi-trash-can-outline</v-icon>
                <v-list-item-title class="red--text">Remove</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <!-- End Dropdown -->
        </template>
      </v-data-table>
      <!-- End Body -->
    </v-container>
  </v-container>
</template>

<script>
//import PopupAccountEdit from '@/components/admin/popup-account-edit';
//import PopupAccountPassword from '@/components/admin/popup-account-password';

export default {
  metaInfo: {
    title: 'Users',
  },
  components: {
    /*PopupAccountEdit: () => import('@/components/admin/popup-account-edit'),
    PopupAccountPassword: () => import('@/components/admin/popup-account-password'),*/
    //PopupAccountEdit,
    //PopupAccountPassword,
  },
  props: {},
  data() {
    return {
      avatar: require('@/assets/images/avatars/002-woman.svg'),
      search: '',
      headers: [
        // { text: 'Avatar', value: 'avatar' },
        {
          text: 'Name',
          align: 'start',
          sortable: true,
          value: 'name',
        },
        { text: 'Type', value: 'type_readable' },
        // { text: 'Flights', value: 'flightCount' },
        { text: 'Logs', value: 'tachlog_count' },
        { text: 'Created', value: 'created_at_readable' },
        { text: 'Action', value: 'action', align: 'end' },
      ],
      types: ['Guest', 'Regular', 'Moderator', 'Admin'],
      entriesRaw: [],
    };
  },
  computed: {
    entries() {
      return this.entriesRaw.map((entry) => {
        const created_at = new Date(entry.created_at);

        return {
          ...entry,
          avatar: this.avatar,
          name: `${entry.name_first}${entry.name_first ? ' ' : ''}${entry.name_last}`,
          type_readable: this.types[entry.type_id],
          created_at_readable: created_at.toLocaleDateString('en-ZA', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
          }),
          // groups_readable: JSON.parse(entry.groups).join(','),
        };
      });
    },
    exportFileName() {
      const date = new Date();
      const year = date.getUTCFullYear();
      const month = date.getUTCMonth() + 1; // months from 1-12
      const day = date.getUTCDate();
      const hours = date.getUTCHours();
      const minutes = date.getUTCMinutes();

      return `exported_accounts_${year}_${month}_${day}__${hours}_${minutes}.csv`;
    },
  },
  async mounted() {
    this.$store.state.setLoading(true);
    await this.refresh();
    this.$store.state.setLoading(false);
  },
  methods: {
    async refresh() {
      const query = await this.$api.account.select_all();
      this.entriesRaw = query.results;
    },
    async create() {
      const fields = await this.$refs.form.open('Create Account');
      if (fields) {
        this.$store.state.setLoading(true);

        await this.$api.account.insert(fields);

        await this.refresh();
        this.$store.state.setLoading(false);
      }
    },
    async edit(item) {
      const fields = await this.$refs.form.open('Edit Account', item);
      if (fields) {
        this.$store.state.setLoading(true);

        try {
          let result = await this.$api.account.update(fields);
          this.$refs.alert.open('success', `Updated successfully.`, 3000);
        } catch (error) {
          console.log(error);
          this.$refs.alert.error(error);
        }

        await this.refresh();
        this.$store.state.setLoading(false);
      }
    },
    async reset(item) {
      const fields = await this.$refs.reset.open('Reset All', item);
      if (fields) {
        this.$store.state.setLoading(true);

        try {
          let result = await this.$api.account.set_password_all(fields);
          this.$refs.alert.open('success', `Password changed successfully for '${result.affectedRows}' entries.`, 3000);
        } catch (error) {
          console.log(error);
          this.$refs.alert.error(error);
        }

        this.$store.state.setLoading(false);
      }
    },
    async remove(item) {
      if (!(await this.$refs.prompt.open('Confirm Deletion', 'Are you sure you want to delete this entry?'))) {
        return;
      }

      this.$store.state.setLoading(true);

      if (await this.$api.account.update({ id: item.id, is_removed: true })) {
        this.$refs.alert.open('success', 'Removed', 2000);
      } else {
        this.$refs.alert.error('Failed to remove', 4000);
      }

      await this.refresh();
      this.$store.state.setLoading(false);
    },
    async toggleEnabled(item) {
      if (
        item.is_enabled &&
        !(await this.$refs.prompt.open('Confirm Disable', 'Are you sure you want to disable this entry?'))
      ) {
        return;
      }

      this.$store.state.setLoading(true);
      if (!(await this.$api.account.update({ ...item, is_enabled: !item.is_enabled }))) {
        this.$refs.alert.error('Toggle enable failed', 4000);
      }

      await this.refresh();
      this.$store.state.setLoading(false);
    },
  },
};
</script>

<style lang="scss" scoped>
.theme--dark.v-toolbar.v-sheet,
.theme--light.v-toolbar.v-sheet {
  background-color: transparent;
}
</style>
