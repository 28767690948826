<template>
  <div class="page-wrap-bg" :class="{ desktop: !$vuetify.breakpoint.mobile }">
    <v-card class="pa-4 py-0" :dark="$vuetify.theme.dark" :flat="$vuetify.breakpoint.mobile">
      <v-row no-gutters style="width: 720px">
        <v-col cols="6" md="6" class="pa-10 pt-8">
          <!--<v-row no-gutters> ABC </v-row>
          <v-spacer />
          <img class="" src="@/assets/images/template/illustrations/posting_photo.svg" />
          -->
          <v-row class="h-full flex-column" no-gutters>
            <v-col cols="auto" class="align-items-center">
              <H5>Registration</H5>
              <h6 class="text--disabled font-weight-medium mb-10">Let's create an account for your business!</h6>
            </v-col>

            <v-col cols="auto">
              <img class="mt-2" src="@/assets/images/template/illustrations/posting_photo.svg" />
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="6" md="6">
          <v-form id="register-form" @submit.prevent="onSubmit">
            <div class="pa-4 mt-8 pr-8">
              <v-text-field label="Full Name" />
              <v-text-field v-model="email" label="Email" :rules="emailRules" required />
              <v-text-field
                v-model="password"
                :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show ? 'text' : 'password'"
                name="input-10-2"
                label="Password"
                :counter="0"
                :rules="passwordRules"
                @click:append="show = !show"
              ></v-text-field>

              <v-checkbox v-model="checkboxTerms">
                <template v-slot:label>
                  <div>
                    I agree to the
                    <a target="_blank" href="" @click.stop class="text-decoration-underline">terms and conditions</a>
                  </div>
                </template>
              </v-checkbox>

              <div class="d-flex align-center">
                <v-btn type="submit" class="mb-4 mt-4" block elevation="0" color="primary" dark form="login-form">
                  Sign Up
                </v-btn>
              </div>
            </div>
          </v-form>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>
<script>
//import firebase from 'firebase/app';

export default {
  name: 'Login',
  metaInfo: {
    // title will be injected into parent titleTemplate
    title: 'Login',
  },
  data() {
    return {
      show: false,
      checkboxTerms: false,
      email: '',
      account_id: 0,
      password: '',
      stayLoggedIn: false,
      loading: false,
      emailRules: [
        (v) => !!v || 'E-mail is required',
        (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid',
        (v) => (v && v.length <= 120) || 'Email must be less than 120 characters',
      ],
      passwordRules: [
        (v) => !!v || 'Password is required',
        (v) => (v && v.length <= 120) || 'Password must be less than 120 characters',
      ],
    };
  },
  mounted() {},
  computed: {
    isLoggedIn() {
      return this.$store.get('session/data').isLoggedIn;
    },
  },
  methods: {
    onSubmit() {
      // this.login({ email: this.email, password: this.password });
      this.$store.session.login({
        account_id: this.account_id,
        password: this.password,
        stayLoggedIn: this.stayLoggedIn,
      });
    },
  },
  watch: {
    isLoggedIn(value) {
      if (value) {
        // this.makeToast('success', 'Successfully Logged In');
        console.log('logged in successfully ');
        this.loading = true;

        this.$router.push('/');
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.page-wrap-bg {
  background-image: url('../../assets/images/session/background1.jpg');
  height: 100vh;
  align-items: center;
  place-content: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
}

.theme--dark.v-card,
.theme--light.v-card {
  background-color: var(--v-card-base);
}

.page-wrap {
  display: flex;
  align-items: center;
  background-color: var(--v-card-base) !important;
}

.page-wrap.desktop {
  background-color: var(--v-background-base) !important;
}

.session-form-hold {
  width: 100%;
  max-width: 200px;
  margin: 0 auto;
}

.border {
  border: 1px solid transparent;
}
</style>
