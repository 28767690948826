var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('popup-prompt',{ref:"prompt"}),_c('popup-certificate-edit',{ref:"form"}),(_vm.$vuetify.breakpoint.smAndDown)?[_c('v-btn',{staticClass:"mr-4 mb-4",attrs:{"fab":"","dark":"","medium":"","color":"primary","fixed":"","right":"","bottom":""},on:{"click":function($event){return _vm.create()}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-plus")])],1)]:_vm._e(),_c('v-container',{staticClass:"mx-auto px-0",class:{ 'py-0': _vm.$vuetify.breakpoint.smAndDown },staticStyle:{"max-width":"850px"}},[_c('v-card',{staticClass:"mb-6 mx-auto text-left",attrs:{"flat":"","color":"transparent"}},[_c('alert',{ref:"alert",staticClass:"mb-6",attrs:{"transition":"expand-transition"}}),(!_vm.$vuetify.breakpoint.smAndDown)?[_c('v-btn',{attrs:{"color":"primary","elevation":"0","width":"102px"},on:{"click":function($event){return _vm.create()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v(" New ")],1),_c('download-csv',{attrs:{"data":_vm.entriesRaw,"name":_vm.exportFileName},on:{"on-export":function($event){return _vm.$store.alert.set({ type: 'success', message: 'Exported: ' + _vm.exportFileName, timeout: 2000 })}}},[_c('v-btn',{staticClass:"ml-3",attrs:{"color":"primary","elevation":"0","outlined":""}},[_vm._v("Export")])],1)]:_vm._e()],2),_c('v-data-table',{staticClass:"elevation-1 px-2 pt-2 pb-2",attrs:{"headers":_vm.headers,"items":_vm.entries,"items-per-page":-1,"search":_vm.search,"sort-by":"label","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.icon",fn:function(ref){
var item = ref.item;
return [_c('tr',[_c('div',{staticClass:"d-flex align-center"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-icon',{attrs:{"color":item.color}},[_vm._v(_vm._s(item.icon))])],1)]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(item.icon)+" "),_c('br'),_vm._v(" "+_vm._s(item.color)+" ")])])],1)])]}},{key:"item.label",fn:function(ref){
var item = ref.item;
return [_c('tr',{class:{ 'text-decoration-line-through': !item.is_enabled }},[_c('div',{staticClass:"d-flex align-center"},[_c('p',{staticClass:"ma-0 font-weight-medium"},[_vm._v(_vm._s(item.label))])])])]}},{key:"footer",fn:function(){return [_c('v-divider'),_c('div',{staticClass:"pa-4 pb-2 text-right"},[_c('div',{staticClass:"text-caption"},[_vm._v(_vm._s(_vm.entries.length)+" items")])])]},proxy:true},{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)]},proxy:true},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","dark":_vm.$vuetify.theme.dark}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){$event.preventDefault();return _vm.edit(item)}}},[_c('v-icon',{staticClass:"pr-4"},[_vm._v("mdi-pencil-outline")]),_c('v-list-item-title',[_vm._v(" Edit ")])],1),_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){$event.preventDefault();return _vm.toggleEnabled(item)}}},[_c('v-icon',{staticClass:"pr-4"},[_vm._v("mdi-account-off-outline")]),_c('v-list-item-title',[_vm._v(_vm._s(item.is_enabled ? 'Disable' : 'Enable'))])],1),_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){$event.preventDefault();return _vm.remove(item)}}},[_c('v-icon',{staticClass:"pr-4",attrs:{"color":"red"}},[_vm._v("mdi-trash-can-outline")]),_c('v-list-item-title',{staticClass:"red--text"},[_vm._v("Remove")])],1)],1)],1)]}}])})],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }