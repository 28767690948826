<template>
  <v-navigation-drawer
    ref="nav"
    v-model="getThemeMode.verticalSidebarDrawer"
    v-bind="$attrs"
    :right="$vuetify.rtl"
    :expand-on-hover="isMini"
    :mini-variant="isMini"
    app
    :dark="$vuetify.theme.dark"
    :mobile-breakpoint="$vuetify.breakpoint.thresholds.sm"
    :floating="true"
    :width="isMiniWidth ? '65px' : '265px'"
    :style="{ width: isMiniWidth ? '65px' : '265px' }"
  >
    <!-- 

      :expand-on-hover.sync
        :expand-on-hover.sync="getThemeMode.verticalSidebarMini"
    :mini-variant="getThemeMode.verticalSidebarMini"
    -->
    <!--NavLogoArea-->
    <template v-slot:prepend>
      <v-list nav height="76" class="py-0" :color="theme.navBarPanelHeader">
        <!--  :color="theme.navBarPanelHeader" :color="theme.navBarPanelHeader" -->
        <v-list-item class="py-0" style="height: 100%">
          <!--LOGO_MAIN-->
          <span style="width: 36px" class="mr-4">
            <v-list-item-avatar rounded="0" size="35">
              <!-- <v-img src="@/assets/images/logo.png" /> -->
              <img fill="#FFFFFF" src="@/assets/images/logo.svg" alt="" />
            </v-list-item-avatar>
          </span>

          <v-list-item-content class="ml-n1">
            <v-list-item-title class="text-18 text-default" @click="toggleNavMini()"> Flying Club </v-list-item-title>

            <v-list-item-subtitle> Cloud 7 </v-list-item-subtitle>
          </v-list-item-content>

          <!--Close Button-->
          <div v-show="$vuetify.breakpoint.smAndDown">
            <v-btn icon color @click.stop="toggleNav">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
        </v-list-item>
      </v-list>

      <!--</v-app-bar>   
      <v-divider v-if="$vuetify.breakpoint.mobile" /> -->
    </template>

    <slot name="default"></slot>

    <template v-slot:append>
      <slot name="append"></slot>
    </template>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  components: {
    ///////Enable scrollbar VuePerfectScrollbar
  },
  props: {
    isAtTop: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      drawer: true,
      permanent: true,
    };
  },
  computed: {
    ...mapGetters(['getThemeMode']),
    theme() {
      return this.$vuetify.theme.themes[this.$vuetify.theme.dark ? 'dark' : 'light'];
    },
    isMini() {
      return this.getThemeMode.verticalSidebarMini;
    },
    isMiniWidth() {
      return this.getThemeMode.verticalSidebarMini && !this.$refs.nav.isMouseover;
    },
  },
  methods: {
    ...mapActions(['toggleNavMini', 'toggleNav']),
    mapItem(item) {
      return {
        ...item,
        children: item.children ? item.children.map(this.mapItem) : undefined,
        title: item.title,
      };
    },
  },
};
</script>

<style lang="scss">
div.theme--light .v-navigation-drawer,
div.theme--dark .v-navigation-drawer {
  /* Nav Background */
  background-color: var(--v-navBarPanel-base);
}

/* Shared */
nav {
  background-color: var(--v-navBarPanel-base);

  .v-navigation-drawer__content,
  .v-navigation-drawer__append {
    /* Item Content Highlighted */
    .v-list-item.v-list-item--active .v-list-item__title,
    .v-list-group.inside .v-list-item.v-list-group__header:not(.v-list-item--active) {
      color: var(--v-navBarItemContentHighlight-base) !important;
    }

    /* Item Icon Highlighted
    .v-list-item.v-list-item--active .v-icon,
    .v-list-group.inside .v-list-item.v-list-group__header:not(.v-list-item--active) .v-icon {
      color: var(--v-navBarItemIconHighlight-base) !important;
    }

    
    /* Item Icon 
    .theme--light.v-list-item:not(.v-list-item--active) i,
    .theme--dark.v-list-item:not(.v-list-item--active) i {
      color: var(--v-navBarItemIcon-base) !important;
    } */

    /* Item Content */
    .theme--light.v-list-item:not(.v-list-item--active),
    .theme--dark.v-list-item:not(.v-list-item--active) {
      color: var(--v-navBarItemContent-base) !important;
    }

    .v-list-item.v-list-item--active:not(.v-list-group__header),
    .v-list-group.inside .v-list-item.v-list-group__header:not(.v-list-item--active) {
      border-left: 4px solid var(--v-navBarBorderAccent-base);
    }

    /* Hide Scrollbar */
    .v-navigation-drawer__content::-webkit-scrollbar {
      display: none;
    }

    .v-navigation-drawer__content {
      -ms-overflow-style: none;
      scrollbar-width: none;
    }

    .v-list-item--active:before,
    .v-list-item--active:hover:before,
    .v-list-item:focus:before {
      opacity: 0;
    }

    .v-list-item.v-list-group__header.v-list-item--active {
      background-color: var(--v-navBarGroupHighlight-base);
    }

    .v-list-item.v-list-item--active:not(.v-list-group__header) {
      background-color: var(--v-navBarItemHighlight-base);
    }

    .v-list-group.borders {
      border-top: 1px solid transparent;
      border-bottom: 1px solid transparent;
    }

    .v-list-group--active.borders {
      border-top: 1px solid var(--v-navBarBorder-base);
      border-bottom: 1px solid var(--v-navBarBorder-base);
    }

    .v-list-item {
      border-left: 4px solid transparent;
    }

    .v-list-item__icon {
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  .theme--light.v-navigation-drawer .v-divider,
  .theme--dark.v-navigation-drawer .v-divider {
    border-top: 1px solid var(--v-border-base);
  }
}

/*
background: rgb(109,40,217);
background: linear-gradient(6deg, rgba(109,40,217,1) 0%, rgba(165,94,236,1) 100%);
*/
</style>
