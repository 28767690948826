<template>
  <v-navigation-drawer
    v-model="visible"
    fixed
    right
    height="100%"
    temporary
    :floating="this.$vuetify.breakpoint.xs"
    :width="this.$vuetify.breakpoint.xs ? '100%' : '350px'"
  >
    <!--
      <v-app-bar
          :color="$vuetify.theme.dark ? getThemeMode.panelColorDark : getThemeMode.panelColor"
          :dark="$vuetify.theme.dark"
          flat
          height="76"
        >-->
    <template v-slot:prepend>
      <v-list nav height="76" style="background: var(--v-cardTitle-base)" class="py-0 px-0">
        <v-list-item class="px-2 py-0" style="height: 100%" @click="toggle()">
          <div class="d-flex justify-space-between align-center w-full h-100 mx-5">
            <v-list-item-title class="text-18 text-default">
              {{ title }}
            </v-list-item-title>
            <v-btn class="mr-n3" icon color @click.stop="toggle()">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
        </v-list-item>
      </v-list>

      <v-divider v-if="!$vuetify.theme.dark" />
    </template>

    <!--
    <div class="relative">
      <v-list nav height="76" class="py-0">
        <v-list-item class="px-2 py-0" style="height: 100%">
          <div class="d-flex justify-space-between align-center w-full h-100 mx-3">
            <v-list-item-title class="text-18 text-default">
              {{ title }}
            </v-list-item-title>
            <v-btn class="mr-n3" icon color @click.stop="toggle()">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
        </v-list-item>
      </v-list>
    </div>
-->

    <slot></slot>

    <template v-slot:append>
      <slot name="append"></slot>
    </template>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  props: {
    /* toggleDrawer: {
      type: Function,
      required: true
    },
    logoutUser: {
      type: Function,
      required: true
    } */
    title: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      visible: false
    };
  },
  methods: {
    toggle() {
      const value = (this.visible = !this.visible);
      this.$emit('toggle', value);
      return value;
    }
  }
};
</script>

<style scoped></style>
