<template>
  <!-- index.vue -->

  <v-app
    :style="{
      background: $vuetify.theme.themes[theme].background,
      color: $vuetify.theme.themes[theme].color,
    }"
    :class="{
      'app-admin-wrap-layout-1 sidebar-mini': getThemeMode.verticalSidebarMini,
      'sidebar-close': !getThemeMode.verticalSidebarDrawer,
    }"
  >
    <nav-bar :is-at-top="$vuetify.isAtTop">
      <template v-slot:default>
        <nav-items :items="nav.itemsTop" />
      </template>

      <template v-slot:append>
        <nav-items :items="nav.itemsBottom" support />
      </template>
    </nav-bar>

    <app-bar :is-at-top="$vuetify.isAtTop"></app-bar>
    <!-- <sub-header>
      
    </sub-header> -->
    <!-- Sizes your content based upon application components -->

    <!-- page -->
    <!-- v-main -->
    <v-main app :class="$vuetify.breakpoint.smAndDown ? 'mt-n16' : 'pt-15'">
      <v-container class="pt-0 px-0 h-full">
        <!-- If using vue-router -->
        <!-- <customizer /> -->
        <!--<v-expand-transition mode="out-in">
          <router-view />
        </v-expand-transition>
        
        <v-fade-transition mode="out-in"><router-view /></v-fade-transition>
       -->
        <v-fade-transition duration="50" mode="out-in"><router-view /></v-fade-transition>
      </v-container>
      <!--<div class="flex-grow-1"></div>

     
      <footer-bar></footer-bar>
-->
    </v-main>
  </v-app>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import NavBar from './nav/nav-bar';
import AppBar from './app-bar/app-bar';
import FooterBar from './footer-bar/footer-bar';
import NavItems from './nav/nav-items';

export default {
  components: {
    /*NavBar: () => import('./nav/nav-bar'),
    AppBar: () => import('./app-bar/app-bar'),
    FooterBar: () => import('./footer-bar/footer-bar.vue'),
    NavItems: () => import('./nav/nav-items.vue'),*/
    NavBar,
    AppBar,
    FooterBar,
    NavItems,
    // Customizer: () => import("@/components/layout/Customizer"),
    // SubHeader: () => import('./sub-header')
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(['getThemeMode']),
    theme() {
      return this.$vuetify.theme.dark ? 'dark' : 'light';
    },
    nav() {
      return this.$store.get('nav/getNav');
    },
  },
  mounted() {
    this.$store.action('nav/refreshItemsTop', this.$api);
    this.$store.action('nav/refreshItemsBottom', this.$api);
  },
};
</script>

<style lang="scss">
/* page */
/*.v-main__wrap {
  flex: 1 1 auto;
  max-width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
}*/

.theme--dark.v-card,
.theme--light.v-card,
.theme--dark.v-data-table,
.theme--light.v-data-table {
  background-color: var(--v-card-base);
}

html {
  overflow-y: auto;
}
</style>
