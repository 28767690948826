<template>
  <div class="page-wrap-bg h-full" :class="{ desktop: !$vuetify.breakpoint.mobile }">
    <!-- Popups -->
    <popup-prompt ref="forgotPassword" />

    <div class="session-form-hold">
      <alert transition="expand-transition" />

      <v-card class="mx-auto pa-4 py-0" :dark="$vuetify.theme.dark" :flat="$vuetify.breakpoint.mobile">
        <v-progress-linear :active="loading" :indeterminate="loading" absolute top color="primary"></v-progress-linear>
        <v-card-text class="text-center">
          <v-avatar rounded="0" size="60" class="mb-4 mt-6">
            <img v-if="$config.demo" src="@/assets/images/logo.svg" alt="" />
            <img v-else src="@/assets/images/logo2.png" alt="" />
          </v-avatar>

          <h6 class="font-weight-light mb-0">Welcome to <span class="font-weight-medium">Cloud 7</span></h6>
          <h6 class="font-weight-medium mb-4">Tach Time Log</h6>
          <!--
          <h6 class="text--disabled font-weight-medium mb-10">
            Sign in to your account
          </h6>
          -->
          <v-form id="login-form" @submit.prevent="onSubmit">
            <field-name :value.sync="account_id" required />

            <!--<v-text-field v-model="email" label="EMAIL" :rules="emailRules" required />-->

            <v-text-field
              v-model="password"
              :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show ? 'text' : 'password'"
              name="input-10-2"
              label="Password"
              :counter="0"
              :rules="passwordRules"
              @click:append="show = !show"
            ></v-text-field>

            <!--<v-checkbox v-model="checkbox1" label="Remember"></v-checkbox>-->
            <v-btn type="submit" class="mb-4 mt-5" block elevation="0" color="primary" dark form="login-form">
              <!--<v-icon left>mdi-login</v-icon>-->
              Sign In
            </v-btn>

            <!--
            <v-btn class="mb-4" block color="#DB4437" dark @click="googleSignIn">
              <v-icon left>
                mdi-google
              </v-icon>

              Sign In
            </v-btn>
            -->

            <div class="d-flex justify-space-between w-full">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on">
                    <v-btn text small :color="stayLoggedIn ? 'success' : 'grey'" class="px-0" @click="toggleStayLoggedIn">
                      Stay Logged In
                    </v-btn>
                  </span>
                </template>

                <span>Use this on your personal device</span>
              </v-tooltip>

              <v-btn text small color="primary" class="px-0" @click="forgotPassword">Forgot Password</v-btn>
            </div>

            <!--<p class="text-right mb-0">
              <v-btn text small color="primary" class="px-0" @click="forgotPassword">Forgot Password</v-btn>
            </p>-->
            <!--
            <div class="px-0 d-flex">
              <v-btn text small color="primary" class="px-0" to="/app/sessions/sign-up-2">Register</v-btn>
            </div>
            -->
          </v-form>
        </v-card-text>
      </v-card>

      <!--
        <v-btn
          v-if="!$config.demo"
          type="submit"
          class="mb-4 mt-5"
          block
          elevation="0"
          color="primary"
          href="https://demo.ocservers.com"
          dark
        >
          CLICK HERE FOR DEMO
        </v-btn>
      -->
    </div>
  </div>
</template>
<script>
//import firebase from 'firebase/app';

export default {
  name: 'Login',
  metaInfo: {
    // title will be injected into parent titleTemplate
    title: 'Login',
  },
  data() {
    return {
      show: false,
      checkbox1: true,
      email: '',
      account_id: 0,
      password: '',
      stayLoggedIn: false,
      loading: false,
      emailRules: [
        (v) => !!v || 'E-mail is required',
        (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid',
        (v) => (v && v.length <= 120) || 'Email must be less than 120 characters',
      ],
      passwordRules: [
        (v) => !!v || 'Password is required',
        (v) => (v && v.length <= 120) || 'Password must be less than 120 characters',
      ],
    };
  },
  mounted() {},
  computed: {
    isLoggedIn() {
      return this.$store.get('session/data').isLoggedIn;
    },
  },
  methods: {
    // login: function() {
    //   firebase.auth().signInWithEmailAndPassword(this.email, this.password)
    //     .then(
    //       user => {
    //         // console.log(user);
    //         this.loading = true;
    //         this.$router.push('/');

    //       },
    //       err => {

    //         // alert(err);

    //       }
    //     )
    // },
    onSubmit() {
      // this.login({ email: this.email, password: this.password });
      this.$store.session.login({
        account_id: this.account_id,
        password: this.password,
        stayLoggedIn: this.stayLoggedIn,
      });
    },
    googleSignIn() {
      const provider = new firebase.auth.GoogleAuthProvider();

      firebase
        .auth()
        .signInWithPopup(provider)
        .then((result) => {
          const newUser = { uid: result.user.uid };
          localStorage.setItem('session', JSON.stringify(newUser));
          this.$router.push('/');
        })
        .catch((err) => {
          alert('Oops' + err.message);
        });
    },
    forgotPassword() {
      this.$refs.forgotPassword.open('Under Construction', 'This feature will arrive in a future update.');
    },
    toggleStayLoggedIn() {
      this.stayLoggedIn = !this.stayLoggedIn;
    },
  },
  watch: {
    // loading (value) {
    //   if (!value) return
    //   setTimeout(() => (this.loading = false), 2000)
    // },
    isLoggedIn(value) {
      if (value) {
        // this.makeToast('success', 'Successfully Logged In');
        console.log('logged in successfully ');
        this.loading = true;

        this.$router.push('/');
      }
    },
    error(value) {
      if (value != null) {
        // this.makeToast("warning", value.message);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.page-wrap-bg {
  height: 100vh;
  align-items: center;
  place-content: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
}

.page-wrap-bg.desktop {
  background-image: url('../../assets/images/session/background1.jpg');
}
/*
var(--v-background-base) !important
background-color: red;

.theme--dark.v-card,
.theme--light.v-card {
  background-color: var(--v-card-base);
}*/

.theme--dark.v-card,
.theme--light.v-card {
  background-color: var(--v-card-base);
}

.page-wrap {
  display: flex;
  align-items: center;
  background-color: var(--v-card-base) !important;
}

.page-wrap.desktop {
  background-color: var(--v-background-base) !important;
}

.session-form-hold {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
}

.border {
  border: 1px solid transparent;
}
</style>
