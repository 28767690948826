<template>
  <v-container>
    <!-- Popups -->
    <popup-prompt ref="prompt" />
    <popup-tachlog-edit ref="form" />

    <v-container class="mx-auto px-0" :class="{ 'py-0': $vuetify.breakpoint.smAndDown }" style="max-width: 850px">
      <v-card class="mb-6 mx-auto text-left" flat color="transparent">
        <!-- Alert -->
        <alert ref="alert" class="mb-6" transition="expand-transition" />

        <!-- Dialog Button Desktop -->
        <template v-if="!$vuetify.breakpoint.smAndDown">
          <!-- Hover -->
          <v-row no-gutters class="px-3 justify-center align-center">
            <v-col>
              <download-csv
                :data="entriesRaw"
                :name="exportFileName"
                @on-export="$store.alert.set({ type: 'success', message: 'Exported: ' + exportFileName, timeout: 2000 })"
              >
                <v-btn color="primary" elevation="0" outlined>Export</v-btn>
              </download-csv>
            </v-col>

            <v-col cols="3" class="ml-n1" :class="$vuetify.breakpoint.smAndDown ? 'text-left' : 'text-right'">
              <v-btn elevation="0" color="transparent" class="my-n2" @click="offset--">
                <v-fade-transition>
                  <v-icon v-show="$vuetify.breakpoint.smAndUp">mdi-arrow-left</v-icon>
                </v-fade-transition>
              </v-btn>
            </v-col>

            <v-col class="text-center">
              <h5 class="mb-0 pb-0">
                {{ getMonthYearString.month }}

                {{ getMonthYearString.year }}
              </h5>
            </v-col>

            <v-col cols="3" class="mr-n1" :class="$vuetify.breakpoint.smAndDown ? 'text-right' : 'text-left'">
              <v-btn elevation="0" color="transparent" class="my-n2" @click="offset++">
                <v-fade-transition>
                  <v-icon v-show="$vuetify.breakpoint.smAndUp && offset != 6" :color="offset == 0 ? 'grey' : 'inherit'"
                    >mdi-arrow-right</v-icon
                  >
                </v-fade-transition>
              </v-btn>
            </v-col>

            <v-spacer />
          </v-row>
          <!-- -->
        </template>
      </v-card>

      <!-- Body -->
      <v-data-table
        :headers="headers"
        :items="entries"
        :items-per-page="-1"
        :footer-props="{
          'items-per-page-options': [20, 100, -1],
        }"
        sort-by="account_name"
        class="elevation-1 px-2 pt-2"
      >
        <!--
        <template v-slot:item.name="{ item }">
          <tr :class="{ 'text-decoration-line-through': !item.is_enabled }">
            <td>
              <div class="d-flex align-center">
                <v-avatar class="mr-2" size="26" dark>
                  <img :src="item.avatar" alt="" />
                </v-avatar>
                <p class="ma-0 pl-2 font-weight-medium">
                  {{ item.name }}
                </p>
              </div>
            </td>
          </tr>
        </template>
        -->

        <template v-slot:top>
          <v-toolbar class="mt-4" flat>
            <v-col class="pl-0">
              <field-name class="pt-5" style="margin-top: 2px" :value.sync="searchAccountId" />
            </v-col>

            <v-col class="pr-0">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Aircraft"
                single-line
                hide-details
              ></v-text-field>
            </v-col>
          </v-toolbar>
        </template>

        <template v-slot:item.action="{ item }">
          <!-- Dropdown -->
          <v-menu right>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon :dark="$vuetify.theme.dark" v-bind="attrs" v-on="on">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>

            <v-list>
              <!-- Edit -->
              <v-list-item link @click.prevent="edit(item)">
                <v-icon class="pr-4">mdi-pencil-outline</v-icon>
                <v-list-item-title>Edit</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <!-- End Dropdown -->
        </template>
      </v-data-table>
      <!-- End Body -->
    </v-container>
  </v-container>
</template>

<script>
export default {
  metaInfo: {
    title: 'Users',
  },
  components: {
    //PopupTachlogEdit: () => import('@/components/user/popup-tachlog-edit'),
  },
  props: {},
  data() {
    return {
      avatar: require('@/assets/images/avatars/002-woman.svg'),
      search: '',
      searchAccountId: null,
      headers: [
        //{ text: 'ID', value: 'id' },
        { text: 'Name', value: 'account_name' },
        { text: 'Tail #', value: 'aircraft_id' },
        { text: 'Model', value: 'aircraft_model' },
        //{ text: 'Tach Start', value: 'tach_start' },
        { text: 'Tach End', value: 'tach_end' },
        { text: 'Duration', value: 'tach_duration' },
        { text: 'Landed', value: 'timestamp_readable' },
        { text: 'Action', value: 'action', align: 'end' },
      ],
      types: ['Guest', 'Regular', 'Moderator', 'Admin'],
      entriesRaw: [],
      offsetRaw: 0,
    };
  },
  computed: {
    getMonthYear() {
      const date = new Date();
      date.setMonth(date.getMonth() + this.offset);
      return { month: date.getMonth(), year: date.getFullYear(), date };
    },
    getMonthYearString() {
      const monthYear = this.getMonthYear;
      const monthNames = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ];

      return { ...monthYear, month: monthNames[monthYear.month] };
    },
    offset: {
      get: function () {
        return this.offsetRaw;
      },
      set: function (value) {
        this.offsetRaw = Math.min(0, value);
      },
    },
    entries() {
      const search = this.search.toLowerCase();
      return this.entriesRaw.filter((entry) => {
        const inSearch =
          entry.aircraft_model.toLowerCase().includes(search) || entry.aircraft_id.toLowerCase().includes(search);
        //entry.id.toString() === search;
        const inSearchAccountId = this.searchAccountId == null || entry.account_id === this.searchAccountId;

        return inSearch && inSearchAccountId;
      });
      /*return this.entriesRaw.map((entry) => {
        const created_at = new Date(entry.created_at);

        return {
          ...entry,
          tachlog_count: entry.has_tachlog ? entry.tachlog_count : 'N/A',
          avatar: this.avatar,
          name: `${entry.name_first}${entry.name_first ? ' ' : ''}${entry.name_last}`,
          type_readable: this.types[entry.type_id],
          created_at_readable: created_at.toLocaleDateString('en-ZA', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
          }),
          // groups_readable: JSON.parse(entry.groups).join(','),
        };
      });*/
    },
    exportFileName() {
      const date = new Date();
      const year = date.getUTCFullYear();
      const month = date.getUTCMonth() + 1; // months from 1-12
      const day = date.getUTCDate();
      const hours = date.getUTCHours();
      const minutes = date.getUTCMinutes();

      return `exported_invoices_${year}_${month}_${day}__${hours}_${minutes}.csv`;
    },
  },
  watch: {
    async offset() {
      this.$store.state.setLoading(true);
      await this.refresh();
      this.$store.state.setLoading(false);
    },
  },
  async mounted() {
    this.$store.state.setLoading(true);
    await this.refresh();
    this.$store.state.setLoading(false);
  },
  methods: {
    async refresh() {
      const usersPromise = this.$api.account.select_all_minimal();
      const entriesPromise = this.$api.tachlog.select_month({ aircraft_id: null, offset: this.offset });
      const aircraftsPromise = await this.$api.aircraft.select_all();

      const users = await usersPromise;
      const entries = await entriesPromise;
      const aircrafts = await aircraftsPromise;

      if (entries) {
        let totals = {
          id: 'Total',
          account_name: '',
          aircraft_id: '',
          aircraft_model: '',
          tach_end: '',
          tach_duration: 0,
          timestamp_readable: '',
        };
        this.entriesRaw = [
          ...entries.map((entry) => {
            const account = users.find((user) => user.id === entry.account_id);
            const aircraft = aircrafts.find((aircraft) => aircraft.id === entry.aircraft_id);

            return {
              ...entry,
              account_name: account?.name || 'Unknown',
              aircraft_id: aircraft?.id || 'Unknown',
              aircraft_model: aircraft?.model || 'Unknown',
              timestamp_readable: new Date(entry.timestamp).toLocaleDateString('en-ZA', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              }),
            };
          }),
        ];
        //, { id: --this.counter }
      }
    },
    async edit(item) {
      const fields = await this.$refs.form.open('Edit Tach Log', item);
      if (fields) {
        this.$store.state.setLoading(true);

        delete fields.account_name;
        delete fields.aircraft_id;
        delete fields.aircraft_model;
        delete fields.timestamp_readable;
        await Promise.all([this.$api.tachlog.update(fields), this.refresh(true)]);

        this.$store.state.setLoading(false);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.theme--dark.v-toolbar.v-sheet,
.theme--light.v-toolbar.v-sheet {
  background-color: transparent;
}
</style>
