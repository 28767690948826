<template>
  <v-container>
    <!-- Popups -->
    <popup-prompt ref="prompt" />
    <popup-certificate-edit ref="form" />

    <!-- Dialog Button Mobile-->
    <template v-if="$vuetify.breakpoint.smAndDown">
      <v-btn fab dark medium color="primary" fixed right bottom class="mr-4 mb-4" @click="create()">
        <v-icon dark>mdi-plus</v-icon>
      </v-btn>
    </template>

    <v-container class="mx-auto px-0" :class="{ 'py-0': $vuetify.breakpoint.smAndDown }" style="max-width: 850px">
      <v-card class="mb-6 mx-auto text-left" flat color="transparent">
        <!-- Alert -->
        <alert ref="alert" class="mb-6" transition="expand-transition" />

        <!-- Dialog Button Desktop -->
        <template v-if="!$vuetify.breakpoint.smAndDown">
          <v-btn color="primary" elevation="0" width="102px" @click="create()">
            <v-icon left>mdi-plus</v-icon>
            New
          </v-btn>

          <download-csv
            :data="entriesRaw"
            :name="exportFileName"
            @on-export="$store.alert.set({ type: 'success', message: 'Exported: ' + exportFileName, timeout: 2000 })"
          >
            <v-btn class="ml-3" color="primary" elevation="0" outlined>Export</v-btn>
          </download-csv>
        </template>
      </v-card>

      <!-- Body -->
      <v-data-table
        :headers="headers"
        :items="entries"
        :items-per-page="-1"
        :search="search"
        sort-by="label"
        hide-default-footer
        class="elevation-1 px-2 pt-2 pb-2"
      >
        <template v-slot:item.icon="{ item }">
          <tr>
            <div class="d-flex align-center">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on">
                    <v-icon :color="item.color">{{ item.icon }}</v-icon>
                  </span>
                </template>

                <span>
                  {{ item.icon }}
                  <br />
                  {{ item.color }}
                </span>
              </v-tooltip>
            </div>
          </tr>
        </template>

        <template v-slot:item.label="{ item }">
          <tr :class="{ 'text-decoration-line-through': !item.is_enabled }">
            <div class="d-flex align-center">
              <p class="ma-0 font-weight-medium">{{ item.label }}</p>
            </div>
          </tr>
        </template>

        <template v-slot:footer>
          <v-divider />
          <div class="pa-4 pb-2 text-right">
            <div class="text-caption">{{ entries.length }} items</div>
          </div>
        </template>

        <template v-slot:top>
          <v-toolbar flat>
            <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details />
          </v-toolbar>
        </template>

        <template v-slot:item.action="{ item }">
          <!-- Dropdown -->
          <v-menu right>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon :dark="$vuetify.theme.dark" v-bind="attrs" v-on="on">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>

            <v-list>
              <!-- Edit -->
              <v-list-item link @click.prevent="edit(item)">
                <v-icon class="pr-4">mdi-pencil-outline</v-icon>
                <v-list-item-title> Edit </v-list-item-title>
              </v-list-item>

              <!-- Enable/Disable -->
              <v-list-item link @click.prevent="toggleEnabled(item)">
                <v-icon class="pr-4">mdi-account-off-outline</v-icon>
                <v-list-item-title>{{ item.is_enabled ? 'Disable' : 'Enable' }}</v-list-item-title>
              </v-list-item>

              <!-- Remove -->
              <v-list-item link @click.prevent="remove(item)">
                <v-icon class="pr-4" color="red">mdi-trash-can-outline</v-icon>
                <v-list-item-title class="red--text">Remove</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <!-- End Dropdown -->
        </template>
      </v-data-table>
      <!-- End Body -->
    </v-container>
  </v-container>
</template>

<script>
//import PopupCertificateEdit from '@/components/admin/popup-certificate-edit';

export default {
  metaInfo: {
    title: 'Certificate',
  },
  components: {
    //PopupCertificateEdit: () => import('@/components/admin/popup-certificate-edit'),
    //PopupCertificateEdit,
  },
  props: {},
  data() {
    return {
      search: '',
      headers: [
        {
          text: 'Label',
          align: 'start',
          sortable: true,
          value: 'label',
        },
        { text: 'Action', value: 'action', align: 'end' },
      ],
      entriesRaw: [],
    };
  },
  computed: {
    entries() {
      return this.entriesRaw;
    },
    exportFileName() {
      const date = new Date();
      const year = date.getUTCFullYear();
      const month = date.getUTCMonth() + 1; // months from 1-12
      const day = date.getUTCDate();
      const hours = date.getUTCHours();
      const minutes = date.getUTCMinutes();

      return `exported_certificates_${year}_${month}_${day}__${hours}_${minutes}.csv`;
    },
  },
  async mounted() {
    this.$store.state.setLoading(true);
    await this.refresh();
    this.$store.state.setLoading(false);
  },
  created() {},
  methods: {
    async refresh(refreshNav) {
      const query = await this.$api.certificate.select_all();
      this.entriesRaw = query.results;
    },
    async create() {
      const fields = await this.$refs.form.open('Create Certificate');
      if (fields) {
        this.$store.state.setLoading(true);

        await this.$api.certificate.insert(fields);

        await this.refresh(true);
        this.$store.state.setLoading(false);
      }
    },
    async edit(item) {
      const fields = await this.$refs.form.open('Edit Certificate', item);
      if (fields) {
        this.$store.state.setLoading(true);

        await this.$api.certificate.update(fields);

        await this.refresh(true);
        this.$store.state.setLoading(false);
      }
    },
    async remove(item) {
      if (!(await this.$refs.prompt.open('Confirm Deletion', 'Are you sure you want to delete this entry?'))) {
        return;
      }

      this.$store.state.setLoading(true);

      if (await this.$api.certificate.update({ id: item.id, is_removed: true })) {
        this.$refs.alert.open('success', 'Removed', 2000);
      } else {
        this.$refs.alert.error('Failed to remove', 4000);
      }

      await this.refresh(true);
      this.$store.state.setLoading(false);
    },
    async toggleEnabled(item) {
      if (
        item.is_enabled &&
        !(await this.$refs.prompt.open('Confirm Disable', 'Are you sure you want to disable this entry?'))
      ) {
        return;
      }

      this.$store.state.setLoading(true);
      if (!(await this.$api.certificate.update({ ...item, is_enabled: !item.is_enabled }))) {
        this.$refs.alert.error('Toggle enable failed', 4000);
      }

      await this.refresh(true);
      this.$store.state.setLoading(false);
    },
  },
};
</script>

<style lang="scss" scoped>
.theme--dark.v-toolbar.v-sheet,
.theme--light.v-toolbar.v-sheet {
  background-color: transparent;
}
</style>
