<template>
  <v-container>
    <!-- Popups -->
    <popup-prompt ref="prompt" />
    <popup-tachlog-edit ref="form" />

    <!-- Dialog Button Mobile-->
    <template v-if="$vuetify.breakpoint.smAndDown">
      <v-btn fab dark medium color="primary" fixed right bottom class="mr-4 mb-4" @click="create()">
        <v-icon dark>mdi-plus</v-icon>
      </v-btn>
    </template>

    <v-container class="mx-auto px-0" style="max-width: 850px">
      <v-card class="mb-6 mx-auto text-left" flat color="transparent">
        <!-- Dialog Button Desktop -->
        <template v-if="!$vuetify.breakpoint.smAndDown || true">
          <!--
          <download-csv
            :data="entriesRaw"
            :name="exportFileName"
            @on-export="$store.alert.set({ type: 'success', message: 'Exported: ' + exportFileName, timeout: 2000 })"
          >
            <v-btn class="ml-3" color="primary" elevation="0" outlined>Export</v-btn>
          </download-csv>
        -->
          <!-- Hover -->
          <v-hover v-slot="{ hover }">
            <v-row no-gutters class="px-3 justify-center align-center">
              <v-col v-if="!$vuetify.breakpoint.smAndDown" cols="1">
                <v-btn color="primary" elevation="0" width="102px" @click="create()">
                  <v-icon left>mdi-plus</v-icon>
                  New
                </v-btn>
              </v-col>

              <v-col cols="3" class="ml-n1" :class="$vuetify.breakpoint.smAndDown ? 'text-left' : 'text-right'">
                <v-btn elevation="0" color="transparent" class="my-n2" @click="offset--">
                  <v-fade-transition>
                    <v-icon v-show="true || hover || $vuetify.breakpoint.smAndUp">mdi-arrow-left</v-icon>
                  </v-fade-transition>
                </v-btn>
              </v-col>

              <v-col class="text-center">
                <h5 class="mb-0 pb-0" style="user-select: none">
                  {{ getMonthYearString.month }}
                  {{ getMonthYearString.year }}
                </h5>
              </v-col>

              <v-col cols="3" class="mr-n1" :class="$vuetify.breakpoint.smAndDown ? 'text-right' : 'text-left'">
                <v-btn
                  elevation="0"
                  color="transparent"
                  class="my-n2"
                  @click="offset++"
                  v-show="offset != 0 || !$vuetify.breakpoint.smAndDown"
                >
                  <v-fade-transition>
                    <v-icon :color="offset == 0 ? 'grey' : 'inherit'">mdi-arrow-right</v-icon>
                  </v-fade-transition>
                </v-btn>
              </v-col>

              <v-col v-if="!$vuetify.breakpoint.smAndDown" class="text-right" cols="1">
                <!--
                <v-fade-transition>
                  <v-btn v-if="hover" width="102px" color="primary" elevation="0" @click="">
                    <v-icon left> mdi-eye </v-icon>

                    View
                  </v-btn>
                </v-fade-transition>
                -->
              </v-col>
            </v-row>
          </v-hover>
          <!-- -->
        </template>
      </v-card>

      <!-- Alert -->
      <alert ref="alert" class="mb-6" transition="expand-transition" />

      <!-- Body -->
      <!-- Entries 
      <v-fade-transition group duration="0">
      <v-slide-x-transition mode="out-in">
      <v-scale-transition group mode="out-in" hide-on-leave>-->
      <v-scale-transition group mode="out-in" origin="top center 0" hide-on-leave>
        <div v-for="(entry, i) of entries" :key="entry.id">
          <!-- Log Entry -->
          <tachlog-entry
            v-if="entry.aircraft_id"
            :entry="entry"
            :users="users"
            :entry-latest="entryLatest"
            @toggle-flag="toggleFlag"
            @edit="edit"
            @remove="remove"
          />

          <!-- Final Message Entry -->
          <v-card
            v-else
            min-width="200px"
            max-width="850px"
            class="mx-auto"
            :class="!$vuetify.breakpoint.smAndDown ? 'mb-6' : 'mb-4'"
          >
            <v-card-title class="blue-grey lighten white--text font-weight-thin pb-3 pt-3 pr-3">
              There are no more entries
            </v-card-title>
          </v-card>
        </div>
      </v-scale-transition>
      <!-- End Body -->
    </v-container>
  </v-container>
</template>

<script>
//import TachlogEntry from '@/components/user/tachlog-entry';

export default {
  metaInfo: {
    title: 'TachLog',
  },
  components: {
    //PopupTachlogEdit: () => import('@/components/user/popup-tachlog-edit'),
    //TachlogEntry: () => import('@/components/user/tachlog-entry'),
    //TachlogEntry,
  },
  props: {
    aircraft_id: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      entries: [],
      users: [],
      offsetRaw: 0,
      counter: -1,
      entryLatest: null,
      aircraft: null,
    };
  },
  computed: {
    exportFileName() {
      const date = new Date();
      const year = date.getUTCFullYear();
      const month = date.getUTCMonth() + 1; // months from 1-12
      const day = date.getUTCDate();
      const hours = date.getUTCHours();
      const minutes = date.getUTCMinutes();

      return `tachlog_aircraft_${year}_${month}_${day}__${hours}_${minutes}.csv`;
    },
    getMonthYear() {
      const date = new Date();
      date.setMonth(date.getMonth() + this.offset);
      return { month: date.getMonth(), year: date.getFullYear(), date };
    },
    getMonthYearString() {
      const monthYear = this.getMonthYear;
      const monthNames = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ];

      return { ...monthYear, month: monthNames[monthYear.month] };
    },
    offset: {
      get: function () {
        return this.offsetRaw;
      },
      set: function (value) {
        this.offsetRaw = Math.min(0, value);
      },
    },
  },
  watch: {
    aircraft_id: {
      async handler(newId, oldId) {
        this.entries = [];
        this.offset = 0;

        this.$store.state.setLoading(true);
        await this.refresh();
        this.$store.state.setLoading(false);
      },
      immediate: true,
    },
    async offset() {
      this.$store.state.setLoading(true);
      await this.refresh(true);
      this.$store.state.setLoading(false);
    },
  },
  created() {},
  methods: {
    async refresh(offsetChanged) {
      if (!offsetChanged) {
        this.$api.account.select_all_minimal().then((data) => {
          this.users = data;
        });

        this.$api.tachlog
          .select_latest({
            aircraft_id: this.aircraft_id,
            account_id: this.$store.session.data.account_id,
          })
          .then((data) => {
            this.entryLatest = data;
          });
      }

      const entries = await this.$api.tachlog.select_month({ aircraft_id: this.aircraft_id, offset: this.offset });
      if (entries) {
        this.entries = [...entries, { id: --this.counter }];
      }

      /*const entryLast = {
        id: this.counter--, //entries.length + 1 != this.entries.length && entries.length > 0 ? this.counter-- : this.counter,
      }; //{ id: Math.abs(entries.length - this.entries.length - 1) >= 2 ? this.counter-- : this.counter };
*/

      //
      /*const aircrafts = await this.$api.aircraft.select_all_minimal();
      if (aircrafts) {
        const aircraft_id = this.aircraft_id;
        this.aircraft = aircrafts.find((aircraft) => aircraft.id === aircraft_id);
        this.$store.nav.setMessage(this.aircraft.label || this.aircraft_id);
      }*/
    },
    async create() {
      const fields = await this.$refs.form.open(`Create Tach Log - ${this.aircraft_id}`, null, this.aircraft_id);
      if (fields) {
        this.$store.state.setLoading(true);

        await this.$api.tachlog.insert(fields);
        await this.refresh();

        this.$store.state.setLoading(false);
      }
    },
    async edit(item) {
      const fields = await this.$refs.form.open(`Create Tach Log - ${this.aircraft_id}`, item, this.aircraft_id);
      if (fields) {
        this.$store.state.setLoading(true);

        await Promise.all([this.$api.tachlog.update(fields), this.refresh()]);

        this.$store.state.setLoading(false);
      }
    },
    async toggleFlag(item) {
      this.$store.state.setLoading(true);

      await Promise.all([this.$api.tachlog.update({ id: item.id, flagged: !item.flagged }), this.refresh()]);

      this.$store.state.setLoading(false);
    },
    async remove(item) {
      if (!(await this.$refs.prompt.open('Confirm Deletion', 'Are you sure you want to delete this entry?'))) {
        return;
      }

      this.$store.state.setLoading(true);

      const result = await this.$api.tachlog.update({ id: item.id, is_removed: true });

      if (result) {
        this.$refs.alert.open('success', 'Removed', 2000);
      } else {
        this.$refs.alert.error('Failed to remove', 4000);
      }

      await this.refresh();

      this.$store.state.setLoading(false);
    },
    /*getTimestampReadable(timestamp) {
      const date = new Date(timestamp);
      const dateSplit = date.toLocaleString('en-US', { weekday: 'long', day: 'numeric', month: 'long' }).split(' ');
      const dateStr = `${dateSplit[1]} ${dateSplit[0]}${nth(17)}`;
      return dateSplit.join(' ');
    },*/
  },
};
</script>

<style lang="scss" scoped>
.theme--dark.v-toolbar.v-sheet,
.theme--light.v-toolbar.v-sheet {
  background-color: transparent;
}
</style>
