<!--
https://www.npmjs.com/package/vue-navigation-bar
getVuetifyPreset.theme.themes.light.background
:color="getThemeMode.appBarColor"
:dark="getThemeMode.appBarColor != 'white' ? true : false"
transparent
-->

<template>
  <div>
    <!--
    <v-sheet class="header-backup"></v-sheet>
    -->

    <!-- :color="$vuetify.theme.dark ? 'dark' : getThemeMode.appBarColor" -->
    <!--:dark="getThemeMode.appBarColor != 'white' ? true : false"-->

    <vue-navigation-bar :options="navbarOptions" />
    <!--Progress Bar-->
    <v-progress-linear
      :active="$store.state.isLoading"
      :indeterminate="$store.state.isLoading"
      absolute
      :top="getThemeMode.isAppbarTransparent"
      color="primary"
    />

    <v-app-bar
      :absolute="!$vuetify.breakpoint.smAndDown"
      :color="color"
      :dark="$vuetify.theme.dark"
      :flat="isAtTop || !$vuetify.breakpoint.smAndDown"
      app
      class="text-left"
      height="76"
    >
      <!--Sidebar Button
      $vuetify.breakpoint.thresholds.sm || !getThemeMode.verticalSidebarDrawer
      -->

      <!-- <v-toolbar-title>Egret Vue</v-toolbar-title> -->
      <v-row no-gutters justify="center" class="mx-1">
        <v-col class="text-left" cols="2">
          <!-- Left -->
          <v-app-bar-nav-icon v-ripple="{ class: 'primary--text' }" class="ml-n1" @click="toggleNav()" />
        </v-col>
        <v-col class="text-center" align-self="center">
          <!-- Center -->
          <v-avatar
            v-if="false && $vuetify.breakpoint.smAndDown && (!message.length || !$vuetify.isAtTop)"
            size="32"
            rounded="0"
          >
            <v-img src="@/assets/images/logo.svg" />
          </v-avatar>

          <template v-else-if="message">
            <H6 v-if="$vuetify.breakpoint.smAndDown" class="ma-0 grey--text text--darken-2">{{ message }}</H6>
            <!--<H5 v-else class="ma-0">{{ message }}</H5>-->
          </template>
        </v-col>
        <v-col class="text-right" cols="2">
          <!-- Right -->
          <v-badge
            v-if="false && !$vuetify.breakpoint.smAndDown"
            bordered
            overlap
            content="3"
            color="#ff5722"
            offset-x="22"
            offset-y="22"
            class="mr-2"
          >
            <v-btn icon @click="toggleDashboardDrawer">
              <v-icon size="32">mdi-bell</v-icon>
            </v-btn>
          </v-badge>

          <!--Search-->
          <!--<v-btn icon @click="searchDrawer = !searchDrawer">
          <v-icon>mdi-magnify</v-icon>-->

          <v-btn class="mr-n1" icon @click="toggleDashboardDrawer">
            <avatar-initials color="#ff5722" size="32" :text="$store.session.data.name_initials" />
          </v-btn>
        </v-col>
      </v-row>

      <!--Avatar
        <v-chip
          v-show="!$vuetify.breakpoint.thresholds.sm"
          class="transparent py-6"
        >
          Watson John
          <v-avatar class="ml-2" color="red">
            <span class="white--text">WJ</span>
          </v-avatar>
        </v-chip>
      </v-btn>-->
    </v-app-bar>

    <!-- drawer-dashboard -->
    <drawer ref="dashboard" title="Dashboard" @logout-user="logout">
      <drawer-dashboard :toggle-drawer="toggleDashboardDrawer" />

      <template v-slot:append>
        <div class="my-4 mx-4">
          <!--
          <base-hover-button text="Signout" block bg-color="primary" icon-name="mdi-logout" @click.native="logout" />
          -->

          <v-btn block color="primary" elevation="0" @click.native="logout"> Signout </v-btn>
        </div>
      </template>
    </drawer>

    <!-- notificationDrawer 
    <v-navigation-drawer v-model="notificationDrawer" fixed right height="100%" temporary floating width="350">
      <template v-slot:append>
        <div class="my-4 mx-4">
          <base-hover-button text="View All Notifications" block bg-color="primary lighten-5 primary--text" />
        </div>
      </template>
    </v-navigation-drawer> -->

    <!-- searchDrawer -->
    <!--
    <v-navigation-drawer
      v-model="searchDrawer"
      fixed
      right
      height="100%"
      temporary
      floating
      width="380"
    >
      <search-drawer>
        <template v-slot:searchDrawerCloseButton>
          <v-btn icon color @click.stop="searchDrawer = !searchDrawer">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
      </search-drawer>
    </v-navigation-drawer>
    -->
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import DrawerDashboard from './drawer/drawer-dashboard';
import Drawer from './drawer/drawer';

export default {
  name: 'VerticalAppBar',
  components: {
    DrawerDashboard,
    Drawer,
    //Drawer: () => import('./drawer/drawer'),
    // NotificationDrawer: () => import('../theme/notification-drawer.vue'),
    // SearchDrawer: () => import('../theme/old/search-drawer.vue'),
  },
  props: {
    isAtTop: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      dashboardDrawer: false,
      notificationDrawer: false,
      searchDrawer: false,
      navbarOptions: {
        elementId: 'main-navbar',
        isUsingVueRouter: true,
        mobileBreakpoint: 992,
        brandImagePath: './',
        brandImageAltText: 'brand-image',
        collapseButtonOpenColor: '#661c23',
        collapseButtonCloseColor: '#661c23',
        showBrandImageInMobilePopup: true,
        ariaLabelMainNav: 'Main Navigation',
        tooltipAnimationType: 'shift-away',
        /* menuOptionsLeft: [
          {
            type: "link",
            text: "Dashboards",
            iconLeft: '<i class="mdi mdi-view-dashboard"></i>',
            subMenuOptions: [
              {
                type: "link",
                text: "Learning Management",
                path: { name: "learning-management" },
                iconLeft: '<i class="mdi mdi-circle-medium"></i>'
              },

              {
                type: "link",
                text: "Job Management",
                path: { name: "job-management" },
                iconLeft: '<i class="mdi mdi-circle-medium"></i>'
              },
              {
                type: "link",
                text: "Analytic",
                path: { name: "analytic" },
                iconLeft: '<i class="mdi mdi-circle-medium"></i>'
              },
              {
                type: "link",
                text: "Cryptocurrency",
                path: { name: "crypto-currency" },
                iconLeft: '<i class="mdi mdi-circle-medium"></i>'
              },
              {
                type: "link",
                text: "Sales",
                path: { name: "sales" },
                iconLeft: '<i class="mdi mdi-circle-medium"></i>'
              },
              {
                type: "link",
                text: "Subscription",
                path: { name: "donation" },
                iconLeft: '<i class="mdi mdi-circle-medium"></i>'
              }
            ]
          }
        ] */
      },
    };
  },
  computed: {
    ...mapGetters(['getThemeMode']),
    color() {
      if (!this.$vuetify.breakpoint.smAndDown || this.isAtTop) {
        return 'transparent';
      }

      return this.$vuetify.theme.dark ? 'inherit' : 'white';
    },
    theme() {
      return this.$vuetify.theme.dark ? 'dark' : 'light';
    },
    message() {
      return this.$store.nav.getMessage;
    },
  },
  watch: {
    '$route.path': {
      async handler(to, from) {
        const messages = this.$store.nav.getPathMessages;

        this.$store.nav.setMessage(messages[to] || '');
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions(['toggleNav', 'toggleNavMini']),
    /* toggleNav() {
      this.toggleNav();

      // this.$emit("update:mini-variant");
      // console.log("check");
    }, */
    toggleDashboardDrawer() {
      return this.$refs.dashboard.toggle();
    },
    logout() {
      this.$store.session.logout();

      //this.$router.push('/session/login');
    },
  },
};
</script>

<style lang="scss" scoped>
.v-app-bar > button {
  background-color: white;
}

.header-backup {
  display: block;
  width: 100%;
  height: 102px;
  position: fixed;
  top: 0;
  z-index: 5;
  background: linear-gradient(
    180deg,
    hsla(0, 0%, 97.3%, 0.95) 44%,
    hsla(0, 0%, 97.3%, 0.46) 73%,
    hsla(0, 0%, 100%, 0)
  ) !important;
  left: 0;
  &.theme--dark {
    background: linear-gradient(180deg, rgba(5, 5, 5, 0.95) 44%, rgba(0, 0, 0, 0.46) 3%, hsla(0, 0%, 100%, 0)) !important;
  }
}
.tippy-box[data-theme~='light'] {
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}
.vnb {
  background: transparent !important;
  &__menu-options {
    margin-top: 3px !important;
    &__option {
      &__link {
        &:focus {
          outline: none;
          border: 1px solid none;
        }
        &:hover {
          color: #0081ff;
          .vnb__menu-options__option__arrow {
            fill: #0081ff;
          }
        }

        &__icon {
          svg {
            fill: #0081ff !important;
          }
        }
      }
    }
  }

  &__sub-menu-options {
    &__option {
      &__link {
        &:focus {
          outline: none;
          border: 1px solid none;
        }
        color: #000 !important;
        &:hover {
          color: #0081ff !important;
        }
      }
    }
  }
}

.vnb__collapse-button {
  &:focus {
    border: 1px solid none;
    outline: none;
  }
  &:after {
    content: '\F035C';
    font-size: 25px;
    font-weight: 600;
    font-family: 'Material Design Icons';
  }
  svg {
    display: none !important;
  }
}

.vnb__popup {
  max-height: 80vh;
  overflow-x: hidden;
  overflow-y: scroll !important;
  .vnb__popup__top__close-button {
    &:focus {
      border: 1px solid none;
      outline: none;
    }
    top: 20px;
    right: -33px;
    svg {
      fill: #000 !important;
    }
  }
}

.theme--light.v-chip:not(.v-chip--active) {
  background-color: #ffffff;
}

.icon-gradient {
  background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285aeb 90%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.border {
  border-style: solid;
  border-width: 1px;
  border-color: #c00000;
}

.v-btn.no-effects::before {
  background-color: transparent;
}
</style>
