<template>
  <perfect-scrollbar
    :options="{ suppressScrollX: true, wheelPropagation: false }"
    class="h-100 rtl-ps-none ps scroll"
    style="height: 100%"
  >
    <div class="pa-7 pt-6">
      <!-- Profile -->
      <div class="heading-label pa-0 ma-0 mb-2">Profile</div>

      <div class="pa-0 ma-0 mb-5">
        <v-list-item class="pa-0">
          <v-list-item-avatar size="36" class="mr-5 ma-0"">
            <!--
               :class="{ border: !$vuetify.theme.dark }
              <img src="@/assets/images/faces/1.jpg" alt="" />-->
            <avatar-initials color="#ff5722" size="36" :text="$store.session.data.name_initials" />
          </v-list-item-avatar>

          <v-list-item-content class="pa-0 ma-0">
            <v-list-item-title>
              <b>{{ nameFull }}</b>
            </v-list-item-title>

            <v-list-item-subtitle>
              {{ nameGroup }}
            </v-list-item-subtitle>
          </v-list-item-content>

          <v-list-item-action class="pa-0 ma-0">
            <v-btn color="primary" elevation="0" class="text-decoration-line-through" @click="loader = 'loading'">
              Edit
            </v-btn>
          </v-list-item-action>
        </v-list-item>

        <v-list-item class="px-0">
          <avatar-darkmode size="36" class="mr-5" @click="toggleDrawer" />

          <v-list-item-content>
            <p class="text-15 ma-0">{{ $vuetify.theme.dark ? 'Light' : 'Dark' }} mode</p>
          </v-list-item-content>
        </v-list-item>
      </div>

      <!-- Stats -->
      <div class="heading-label pa-0 ma-0 mb-2">Stats</div>

      <div class="px-0 pb-0 mb-5">
        <v-list-item v-for="(stat, i) of stats" :key="i" class="px-0">
          <v-avatar class="mr-4" :color="stat.color" size="36">
            <span class="white--text">{{ stat.value }}</span>
          </v-avatar>

          <v-list-item-content>
            <v-list-item-title>{{ stat.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </div>

      <!-- Activity -->
      <div class="heading-label pa-0 ma-0 mb-2">activity</div>

      <div class="pa-0 ma-0">
        <v-list-item v-for="(activity, i) of activities" :key="i" class="pa-0">
          <v-avatar class="mr-4 align-self-center" :color="getRandomColor()" size="36">
            <v-icon class="" color="white" size="20">
              {{ activity.icon }}
            </v-icon>
          </v-avatar>

          <v-list-item-content class="pa-0 ma-0">
            <v-list-item-title>{{ activity.message }}</v-list-item-title>

            <v-list-item-subtitle v-if="activity.created_at">{{ new Date(activity.created_at) | ago }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </div>
    </div>
  </perfect-scrollbar>
</template>

<script>
let colorIndex = 0;

export default {
  name: 'DashboardDrawer',
  props: {
    toggleDrawer: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      stats: {
        usersOnline: {
          title: 'Online',
          color: 'green',
          value: 3,
        },
        tachlogsTotal: {
          title: 'Tach Logs',
          color: 'deep-purple',
          value: 2,
        },
        //flightsLanded: 0, blue
      },
      activities: [
        {
          icon: 'mdi-help',
          message: `Nothing to see here.`,
          created_at: null,
        },
      ],
    };
  },
  mounted() {
    this.refresh();
    this.interval = setInterval(() => this.refresh(), 30000);
  },
  destroyed() {
    clearInterval(this.interval);
  },
  methods: {
    getRandomColor() {
      const colors = [
        'red',
        'success',
        'primary',
        'purple',
        'deep-purple',
        'indigo',
        'teal',
        'green',
        'yellow darken-2',
        'orange',
      ];
      colorIndex = colorIndex % colors.length;
      return colors[colorIndex++];
    },
    async refresh() {
      const result = await this.$api.status.get_all({ account_id: this.$store.session.data.account_id });

      colorIndex = 0;
      this.stats.usersOnline.value = result.users_online;
      this.stats.tachlogsTotal.value = result.tachlogs_total;
      this.activities = result.activities;
    },
  },
  computed: {
    nameFull() {
      const { name_first, name_middle, name_last } = this.$store.session.data;
      return [name_first, name_middle, name_last].join(' ');
    },
    nameGroup() {
      switch (this.$store.session.data.type_id) {
        case 0:
          return 'Guest';
        case 1:
          return 'User';
        case 2:
          return 'Moderator';
        case 3:
          return 'Admin';
      }
    },
  },
};
</script>

<style scoped>
.border {
  border-style: solid;
  border-width: 1px;
  border-color: #dadada;
}
</style>
